import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import ButtonPurple from "../../components/UI/ButtonPurple";
import arrow from '../../img/arrow.svg';
import promiseImageDesktop from '../../img/promise-desktop.jpg';
import promiseImageMobil from '../../img/promise.jpg';
import success from '../../img/success.svg';
import { breakpointLarge } from '../../styles/breakpoints';

const SectionContent = styled.div`
  display: flex;
  flex-direction:column;
  background-color: ${props => props.theme.yellow};
  justify-content:center;
  aling-items:center;
  @media (min-width: ${breakpointLarge}) {
    flex-direction:row;
  }
`;
const Textcontainer = styled.div`
  position: relative;
  @media (min-width: ${breakpointLarge}) {
    width: 600px; 
  }
`;
const Column2 = styled.div`
  color: black;
  position: relative; 
  display: flex; 
  flex-direction: column;
  align-items: start;
  justify-content: start;
  transform: none;
  padding:1rem 1rem;
  @media (min-width: 550px) {
    align-items: center;
    justify-content: center;
    padding:1rem 4rem;
  }
  @media (min-width: ${breakpointLarge}){
    align-items: center;
    justify-content: center;
    align-items: flex-start;
    position: static; 
    transform: translate(-135px, 0); 
    padding:0;
  }
  @media (min-width: 1250px){
    transform: translate(-65px, 0); 
  }
`;
const  Column1 = styled.div`
  display: flex; 
  justify-content: center;
  align-items: center;
`
const Title = styled.h2`
  color:black;
  background-color: ${p => p.theme.yellow};
  border-radius: 1.3rem;
  font-size: 2.5rem;
  line-height: 0.9;
  font-weight: 800;
  width: 100%;
  span {
    color:white;
    background-color: ${p => p.theme.purple};
    border-radius:0.8rem;
    Padding:0 0.3rem 0 0.3rem;
    margin:0;
  }
  margin-bottom: 0.7rem;
  @media (min-width: 450px) {
    width: 400px;
    font-size: 3rem;
  }
  @media (min-width: ${breakpointLarge}) {
    font-size: 3.2rem;
    margin-bottom: 1.25rem;
  }
`;
const Description = styled.div`
  line-height: 1;
  text-align: left;
  width: 290px;
  span {
    font-weight: 800;
  }
  @media (min-width: 450px) {
    width: 410px;
  }
`;
const Items = styled.ul`
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  margin: 1rem 0.5rem 0rem 0;
  padding:0;
  list-style: none;
  li {
    display: flex;
    line-height: 1;
  }
  @media (min-width: ${breakpointLarge}){
    margin: 1rem 0.5rem 1rem 0;
  }
 
`;
const Btn = styled(ButtonPurple)`
  margin:0 1rem;
  position: absolute;
  font-size: 1.3rem;
  display: flex;
  color: #fff;
  background-color: ${p => p.theme.purple};
  border-color: ${p => p.theme.purple};
  font-weight: 800;
  padding: 0.5rem 1.3rem;
  p{
    margin:0;
    padding-top: 0.25rem;
  }
  bottom:-250px;
  @media (min-width: 560px) {
    bottom:-320px;
    padding: 0.8rem 1.8rem;
  }
  @media (min-width: ${breakpointLarge}){
    margin:0;
    bottom:-75px;
    font-size: 1.6rem;
  }
  
`;
const ContentBtn = styled.div`
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  @media (min-width: ${breakpointLarge}){
    justify-content:start;
  }
`
const Arrow = styled.img`
  width:15px;
  margin-left:20px;
  @media (min-width: ${breakpointLarge}){
    width:15px;
  }
`
const SeccionText = styled.div`
  border-radius: 1.3rem;
  display:flex;
  background-color: white;
  padding-right: 1.5rem;
  aling-items:center;
  p {
    margin:10px 0;
  }
`
const Success = styled.img`
  width:20px;
  margin:6px;
  @media (min-width: ${breakpointLarge}){
    width:25px;
    margin: 8px;
  }
`
const Text = styled.div`
  margin: 0.3rem 0;
  font-weight: 500;
`;
const ImgMobil= styled.img`
  display:block;
  max-width: 500px;
  height: 390px;
  @media (min-width: 560px) {
    max-width: 600px;
    height: 500px;
  }
  @media (min-width: ${breakpointLarge}) {
    display:none;
  }
`
const ImgDesk= styled.img` 
  padding-top:20px;
  display:none;
  max-width: 800px;
  height: 700px;
  transform: none;
  @media (min-width: ${breakpointLarge}) {
    display:block;
    transform: none;
    position: static; 
  }
  @media (min-width: 1250px){
    transform: translate(70px, 0); 
  }
`
const Promise = ({ title, description, items }) => {
  const regex = /\d+ veces/;
  const match = title.match(regex);
  const variable = match ? match[0] : '';
  const textoWithStyle = title.replace(regex, `<span>${variable}</span>`);
  const commaIndex = description.indexOf('.');
  const textoAntesComa = description.slice(0, commaIndex + 1);
  const textoDespuesComa = description.slice(commaIndex + 1);
  
  return (
      <SectionContent>
        <ImgDesk src={promiseImageDesktop} alt="Juancho te presta" />
        <Textcontainer>
          <Column2>
            <Title dangerouslySetInnerHTML={{ __html: textoWithStyle }} />
            <Description>{textoAntesComa}<span>{textoDespuesComa}</span></Description>
            <Items>
              {items.map(item => (
                <Text key={item.text}>
                  <li key={item.text}>
                    <SeccionText> 
                      <Success src={success} alt="Juancho te presta" />
                      <p>{item.text}</p>
                    </SeccionText>
                  </li>
                </Text>
              ))}
            </Items>
            <ContentBtn>
              <Btn href="/requisitos"><p>Dale un vistazo a los requisitos</p><Arrow src={arrow} alt="Juancho te presta" /></Btn>
            </ContentBtn>
          </Column2>
        </Textcontainer>
        <Column1>
          <ImgMobil src={promiseImageMobil} alt="Juancho te presta" />
        </Column1>
         
      </SectionContent>
  );
};

Promise.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
    })
  ),
};

export default Promise;
